<template>
  <v-dialog
    v-model="showPromptRefresh"
    max-width="300px"
  >
    <v-card>
      <v-card-title class="justify-center">
        <div class="darkblue--text subtitle-2">
          <span v-if="isIdlePrompt">
            Session Expired
          </span>

          <span v-else-if="isUnauthPrompt">
            Signed Out
          </span>

          <span v-else>
            New Content Available
          </span>
        </div>
      </v-card-title>

      <v-card-text v-if="isIdlePrompt">
        Without a response, sign out will occur
        in {{ countdownTimeRemainingInSeconds }} seconds ...
      </v-card-text>

      <v-card-text v-if="isUnauthPrompt">
        Your session was terminated. This typically occurs when
        your session no longer meets security or connection requirements.
      </v-card-text>

      <v-card-text v-else>
        Your browser's cache is receiving updates from the latest version.
      </v-card-text>

      <v-divider />

      <v-card-actions
        v-if="isIdlePrompt || isUnauthPrompt"
        class="justify-center"
      >
        <v-btn
          :disabled="refreshing"
          class="col-6"
          color="coolgray"
          small
          text
          @click.stop="onSignOut"
        >
          Load Home
        </v-btn>

        <v-divider vertical />

        <v-btn
          :loading="refreshing"
          class="col-6"
          color="primary"
          small
          text
          @click.stop="signIn"
        >
          Sign In
        </v-btn>
      </v-card-actions>

      <v-card-actions
        v-else
        class="justify-center"
      >
        <v-btn
          :disabled="refreshing"
          class="col-6"
          color="coolgray"
          small
          text
          @click.stop="onCancel"
        >
          Cancel
        </v-btn>

        <v-divider vertical />

        <v-btn
          :loading="refreshing"
          class="col-6"
          color="primary"
          small
          text
          @click.stop="onRefresh"
        >
          Refresh
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const {
  mapActions: mapAuthActions,
  mapGetters: mapAuthGetters,
} = createNamespacedHelpers('auth')
const { mapState } = createNamespacedHelpers('layout')

export default {
  name: 'RefreshModal',
  data: () => ({
    countdownTimeRemaining: 30000,
    countdownTimeRemainingMax: 30000,
    countdownTimeRemainingIntervalId: null,
    idleTimeRemaining: 1800000,
    idleTimeRemainingMax: 1800000,
    idleTimeRemainingIntervalId: null,
    promptType: 'refresh',
    refreshing: false,
  }),
  computed: {
    ...mapAuthGetters(['isActiveUser']),
    ...mapState({
      promptRefresh: (state) => state.promptRefresh,
      serviceWorkerRegistration: (state) => state.serviceWorkerRegistration,
    }),
    isIdle: {
      get() {
        return this.$store.state.idleVue.isIdle
      },
    },
    isIdlePrompt() {
      return this.promptType === 'idle'
    },
    isUnauthPrompt() {
      return this.promptType === 'unauth'
    },
    countdownTimeRemainingInSeconds() {
      if (this.countdownTimeRemaining < 1000) return 0

      return this.countdownTimeRemaining / 1000
    },
    showPromptRefresh: {
      get() {
        return this.promptRefresh
      },
      set(value) {
        this.$store.commit('layout/promptRefresh', value, { root: true })
      },
    },
    registration: {
      get() {
        return this.serviceWorkerRegistration
      },
      set(newValue) {
        this.$store.commit('layout/serviceWorkerRegistration', newValue)
      },
    },
  },
  watch: {
    isIdle(newValue) {
      if (newValue === true) {
        this.idleTimeRemainingIntervalId = setInterval(() => {
          this.idleTimeRemaining -= 1000

          if (this.isActiveUser && this.idleTimeRemaining < 1000) {
            clearInterval(this.idleTimeRemainingIntervalId)

            this.promptType = 'idle'
            this.setIdleCounter()
            this.showPromptRefresh = true
          }
        }, 1000)
      } else if (this.countdownTimeRemaining > 0) {
        this.showPromptRefresh = false
        this.resetIdleCounter()
      }
    },
    isActiveUser(newValue, oldValue) {
      const { isIdle, showPromptRefresh } = this
      const signedOut = oldValue && !newValue

      if (signedOut && !isIdle && !showPromptRefresh) {
        this.promptType = 'unauth'
        this.showPromptRefresh = true
      }
    },
  },
  created() {
    const { notifyOfUpdate, promptReload } = this

    document.addEventListener('swUpdated', notifyOfUpdate, { once: true })

    if (!navigator.serviceWorker) {
      const logger = console
      logger.warn('Cannot listen to controllerchange on navigator.serviceWorker')
      return null
    }

    navigator.serviceWorker
      .addEventListener('controllerchange', promptReload)

    return { notifyOfUpdate, promptReload }
  },
  methods: {
    ...mapAuthActions(['signIn', 'signOut']),
    notifyOfUpdate(event) {
      this.registration = event.detail
      this.promptType = 'refresh'
      this.showPromptRefresh = true
    },
    promptReload(event) {
      event.preventDefault()

      this.notifyOfUpdate(event)
    },
    reloadApp(event) {
      if (event) event.preventDefault()

      if (this.refreshing) return false

      this.refreshing = true

      window.location.reload()

      this.refreshing = false

      return this.refreshing
    },
    onRefresh() {
      this.showPromptRefresh = false

      if (this.registration && this.registration.waiting) {
        // Send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }

      this.reloadApp()
    },
    setIdleCounter() {
      this.countdownTimeRemainingIntervalId = setInterval(() => {
        this.countdownTimeRemaining -= 1000

        const hasBeenIdle = this.$store.state.idleVue.isIdle

        if (!hasBeenIdle) {
          clearInterval(this.countdownTimeRemainingIntervalId)

          this.onCancel()

          return hasBeenIdle
        }

        if (this.countdownTimeRemaining < 1000) {
          clearInterval(this.countdownTimeRemainingIntervalId)

          this.onSignOut()
        }

        return hasBeenIdle
      }, 1000)
    },
    resetIdleCounter() {
      clearInterval(this.idleTimeRemainingIntervalId)
      clearInterval(this.countdownTimeRemainingIntervalId)

      this.idleTimeRemaining = this.idleTimeRemainingMax
      this.countdownTimeRemaining = this.countdownTimeRemainingMax
    },
    onCancel() {
      this.showPromptRefresh = false
      this.refreshing = false

      this.resetIdleCounter()
    },
    onSignOut() {
      this.onCancel()
      this.signOut('Refresh Modal')
    },
  },
}
</script>
